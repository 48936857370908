/* ========================================================================
 * Apricot's Lazy Load, Image Module
 * ======================================================================== */

// javaScript
import CBResponsiveImage from "./CBResponsiveImage";
import Utils from "./CBUtils";

/**
 * Image lazy loader
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Array} data.selectors
 * @param {Boolean} data.responsive
 * @param {Boolean} data.breakpointListener
 * @param {Boolean} data.infinite
 * @param {Boolean} data.native
 * @returns {{destroy: Function}}
 */

const LazyLoad = (data = {}) => {
  const defaultData = {
    elem: null,
    selectors: [],
    responsive: false,
    breakpointListener: false,
    infinite: false,
    native: false,
  };

  let lazyTargets = null;
  let elem = data.elem;
  if (!Utils.elemExists(elem)) elem = document;
  data = { ...defaultData, ...data };

  let responsive = Utils.isTrue(data.responsive);
  let infinite = Utils.isTrue(data.infinite);
  let native = Utils.isTrue(data.native);

  const init = () => {
    elem.lazyLoadPlugin = "cb";

    // Only proceed if browser is not supporting lazy loading and native is not active
    if (native && "loading" in HTMLImageElement.prototype) {
      return false;
    }

    // Sets an observer for each image
    lazyTargets =
      data.selectors.length > 0
        ? elem.querySelectorAll(data.selectors)
        : elem.querySelectorAll("[data-cb-lazy]");

    lazyTargets.forEach(lazyLoad);
  };

  const lazyLoad = (target) => {
    if (responsive) {
      const lazy = Utils.attr(target, "data-cb-lazy")
      if (lazy !== "" && Utils.attr(target, "data-cb-default-image") !== lazy) {
        Utils.attr(target, "data-cb-default-image", lazy);
      }
    }

    if ("IntersectionObserver" in window) {
      const obs = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            const src = getSource(img);

            setImage(img, src);
            Utils.addClass(img, "cb-active-effect");

            if (data.breakpointListener) {

              img.responsiveImagePlugin = null;
              // activate CBResponsiveImage, after the lazy load has been applied the first time
              CBResponsiveImage({
                elem: img,
                cbImageOnly: false,
              })
            }

            if (!infinite) {
              observer.disconnect();
            }
          }
        });
      });
      obs.observe(target);
    } else {
      const src = getSource(target);

      setImage(target, src);
      Utils.removeClass(target, "cb-lazy");
    }
  };

  const getSource = (img) => {
    let src = "";
    let v = Utils.viewport().prefix;
   
    if (responsive) {
      img.responsiveImagePlugin = null;

      src = CBResponsiveImage({
        elem: img,
        cbImageOnly: true,
      }).getImage(v);
    } else {
      src = img.getAttribute("data-cb-lazy");

      const event = new CustomEvent("apricot_imageChange");
      img.dispatchEvent(event);
    }

    return src;
  };

  const setImage = (img, url) => {
    //Check if we are dealing with an image element
    if (img.tagName === "IMG") {
      Utils.attr(img, "src", url);
    } else {
      img.style.backgroundImage = "url(" + url + ")";
    }

    const event = new CustomEvent("apricot_imageChange");
    img.dispatchEvent(event);
  };

  const destroy = () => {
    if (elem.lazyLoadPlugin === "cb") {
      elem.lazyLoadPlugin = null;

      lazyTargets.forEach((elem) => {
        elem.removeEventListener("apricot_imageChange");
      });
    }
  };

  if (elem.lazyLoadPlugin !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default LazyLoad;
