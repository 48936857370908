/* ========================================================================
 * Apricot's Menu Item Radio
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/menu-item-radio.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Menu Item Radio
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.defaultValue
 * @param {Function} data.onChange
 * @param {Function} data.onChangeApricot
 * @returns {{destroy: Function}}
 */
const MenuItemRadio = (data = {}) => {
  const defaultData = {
    elem: null,
    defaultValue: null,
    onChange: null,
    onChangeApricot: null,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let buttons = null;

  if (!Utils.elemExists(elem)) return false;

  const init = () => {
    elem.menuItemRadio = "cb";

    buttons = elem.querySelectorAll("button");
    const defaultValue = data.defaultValue;

    buttons.forEach((btn) => {
      btn.addEventListener("click", clickEvent);
      btn.addEventListener("keydown", keydownEvent);

      Utils.attr(btn, "tabIndex", "-1");
      if (defaultValue) {
        if (btn.getAttribute("value") === defaultValue) {
          btn.setAttribute("aria-checked", "true");
          if (btn.disabled) {
            defaultTabIndex();
          } else {
            Utils.attr(btn, "tabIndex", "0");
          }
        }
      } 
    });
    if (!defaultValue) {
      defaultTabIndex();
    }
  };
  const resetAll = () => {
    buttons.forEach((btn) => {
      btn.setAttribute("aria-checked", "false");
    });
  };
  const resetTabIndex = () => {
    buttons.forEach((btn) => {
      Utils.attr(btn, "tabIndex", "-1");
    });
  };
  const defaultTabIndex = () => {
    buttons.forEach((btn, index) => {
      if (index === 0) {
        Utils.attr(btn, "tabIndex", "0");
      }
    });
  };
  const clickEvent = (e) => {
    const btn = e.currentTarget;
    const v = btn.getAttribute("value");
    onChange(btn, v);

    resetAll();
    btn.setAttribute("aria-checked", "true");
  };
  const keydownEvent = (e) => {
    const btn = e.currentTarget;
    const k = e.which || e.keyCode;
    const tabbingBack = e.shiftKey;

    // enter, space
    if (k === 13 || k === 32) {
      const v = btn.getAttribute("value");
      onChange(btn, v);
    }

    // up, down, tab, shiftTab
    if (k !== 40 && k !== 38 && k !== 9 && !(k === 9 && tabbingBack)) {
      return;
    }

    if ((k === 9 && tabbingBack) || k === 9) {
      // resetTabIndex();
      // setTabIndex();
    } else {
      e.preventDefault();
      e.stopPropagation();

      let index = 0;
      const items = elem.querySelectorAll("button:not([disabled])");
      Array.prototype.forEach.call(items, (item, i) => {
        if (btn === item) {
          index = i;
        }
      });

      if (k === 38) {
        index--; //up
      } else if (k === 40) {
        index++; //down
      }

      if (index < 0 || index === items.length) {
        return;
      }

      const newActive = items[index];
      resetTabIndex();
      newActive.setAttribute("tabIndex", 0);
      newActive.focus();
    }
  };
  const onChange = (btn, value) => {
    data.onChangeApricot && data.onChangeApricot(btn, value);
    data.onChange && data.onChange(value);
  };
  const destroy = () => {
    if (elem.menuItemRadio === "cb") {
      elem.menuItemRadio = null;
      buttons.forEach((btn) => {
        btn.removeEventListener("click", clickEvent);
        btn.removeEventListener("keydown", keydownEvent);
      });
    }
  };
  if (elem.menuItemRadio !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default MenuItemRadio;
