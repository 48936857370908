/* ========================================================================
 * Apricot's Band
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/band.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Band Hero Polygon
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @returns {{destroy: Function}}
 */
const HeroPolygon = (data = {}) => {
  const defaultData = {
    elem: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;

  if (!Utils.elemExists(elem)) return null;

  let card = null;
  let polygon = null;

  const init = () => {
    elem.bandHeroPolygon = "cb";

    card = elem.querySelector(".cb-card");
    polygon = elem.querySelector(".cb-band-hero-card-polygon");

    if (!Utils.elemExists(card)) return null;
    if (!Utils.elemExists(polygon)) return null;

    polygonSize();
    window.addEventListener("resize", calculateSize);
  };

  const calculateSize = () => {
    const prefix = Utils.viewport().prefix;
    if (prefix !== "xs" && prefix !== "sm") {
      return;
    }
    polygonSize();
  };

  const polygonSize = () => {
    const height = Utils.height(card);
    const path = `polygon(0px ${height}px, 100% ${height}px, 100% 0px, 0px ${height - 144}px)`;
    polygon.style.shapeOutside = path;
    polygon.style.clipPath = path;
    polygon.style.height = `${height}px`;
  };
  const destroy = () => {
    if (elem.bandHeroPolygon === "cb") {
      elem.bandHeroPolygon = null;
    }

    window.removeEventListener("resize", calculateSize);
    polygon.style = null;
  };

  if (elem.bandHeroPolygon !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

const CBBand = {
  HeroPolygon,
};

export default CBBand;
