/* ========================================================================
 * Apricot's Input Mask Module
 * ======================================================================== */

// SCSS
import "../scss/includes/input-mask.scss";

// javaScript
import Utils from "./CBUtils";

// ------------------------------------  INPUT MASK
/**
 * Input Mask
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.cbPlaceholder
 * @param {String} data.cbMask
 * @param {String} data.cbChar
 * @param {String} data.cbNum
 * @param {Function} data.callBack
 * @returns {{destroy: Function}}
 *
 */
const InputMask = (data = {}) => {
  const defaultData = {
    elem: null,
    cbPlaceholder: null,
    cbMask: null,
    cbChar: "_",
    cbNum: "#xXdDmMyY9",
    callBack: null,
  };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return false;

  const tmp = elem.dataset;
  data = { ...defaultData, ...data };
  data = { ...data, ...tmp };

  let cbMask = data.cbMask;
  let cbPlaceholder = data.cbPlaceholder;

  let char = data.cbChar;
  let num = data.cbNum;

  const init = () => {
    elem.inputMaskPlugin = "cb";

    if (cbPlaceholder) {
      Utils.attr(elem, "placeholder", cbPlaceholder);
      Utils.attr(elem, "maxlength", cbPlaceholder.length);
    }

    elem.addEventListener("input", handleValueChange);
  };

  const handleValueChange = (e) => {
    const { value, selectionStart, selectionEnd } = e.target;

    if (value.length > 0 && e.inputType !== "deleteContentBackward") {
      const newValue = setValue(e);
      const diff = newValue.length - value.length;
      const adjustedCursorStart = selectionStart + diff;
      const adjustedCursorEnd = selectionEnd + diff;

      data.callBack && data.callBack(newValue, value)
      // Change event was getting blocked
      setTimeout(() => {
        elem.value = newValue;
        const newStart = Math.min(adjustedCursorStart, newValue.length);
        const newEnd = Math.min(adjustedCursorEnd, newValue.length);
        elem.setSelectionRange(newStart, newEnd);
      }, 0);
    }
  };

  const setValue = (e) => {
    const placeholder = cbMask || cbPlaceholder;
    const { value } = e.target;
    let newValue = "";
    let strippedValue = "";
    let isInt = false;
    let isLetter = false;
    let matchesNumber = false;
    let matchesLetter = false;

    // strip special characters
    strippedValue = cbMask ? value.replace(/\W/g, "") : value.replace(/\D/g, "");

    for (let i = 0, j = 0; i <= placeholder.length; i++) {
      if (placeholder[i] === undefined) break;
      isInt = !isNaN(parseInt(strippedValue[j]));
      isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false;
      matchesNumber = num.indexOf(placeholder[i]) >= 0;
      matchesLetter = char.indexOf(placeholder[i]) >= 0;

      switch (true) {
        case matchesNumber && isInt:
          newValue += strippedValue[j++];
          break;
        case !cbMask && !isInt && matchesNumber:
        case cbMask && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)):
          return newValue;
        default:
          newValue += placeholder[i];
      }
      if (strippedValue[j] === undefined) {
        break;
      }
    }

    return newValue;
  };

  const destroy = () => {
    if (elem.inputMaskPlugin === "cb") {
      elem.inputMaskPlugin = null;
    }

    Utils.removeAttr(elem, "maxlength");
    elem.removeEventListener("input", handleValueChange);
  };

  if (elem.inputMaskPlugin !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default InputMask;
