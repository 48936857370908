/* ========================================================================
 * Apricot's Icons
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/icon.scss";

// javaScript
export default {};
